import alt from '../alt';
import generateWithAsyncActions from 'epic-alt-utils';

/**
 * LoginActions
 */
export default generateWithAsyncActions(alt, [], [
    'handleLogout',
    'loginListener',
    'requestLogin'
]);
