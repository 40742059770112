import alt from '../alt';
import Immutable from 'seamless-immutable';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils/index';

@seamlessImmutable
class GameInfoStore {
    constructor() {
        this.on('error', (error) => {console.error(error);});
        this.on('bootstrap', seamlessBootstrap.bind(this, null));
        this.state = Immutable({
            infinityBladeIPageData: null,
            infinityBladeIIPageData: null,
            infinityBladeIIIPageData: null,
            infinityBladeUniversePageData: null
        });
    }
}

export default alt.createStore(GameInfoStore, 'GameInfoStore');
