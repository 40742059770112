import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import qs from 'qs';
import classNames from 'classnames';
import {connectToStores} from 'epic-alt-utils/index';
import {withRouter} from 'react-router';
import HeaderStore from './stores/HeaderStore';
import LocalizationStore from './stores/LocalizationStore';
import CtaModalStore from './stores/CtaModalStore';
import HomeStore from './stores/HomeStore';
import BuyNowCTAModal from './components/sections/home/BuyNowCTAModal';
import getRoutes from './routes/routes';
import {getMetaTags} from './utils/Utils';
import {EpicGamesNavWrapper} from './components';
import Footer from './components/sections/footer/Footer';

@connectToStores
class App extends React.PureComponent {
    static propTypes = {
        location: PropTypes.object,
        history: PropTypes.object,
        localizationStore: PropTypes.object,
        headerStore: PropTypes.object,
        ctaModalStore: PropTypes.object,
        homeStore: PropTypes.object,
        mobile: PropTypes.bool,
        tablet: PropTypes.bool,
        msie: PropTypes.bool,
        firefox: PropTypes.bool
    };

    static getStores() {
        return [LocalizationStore, HeaderStore, CtaModalStore, HomeStore];
    }

    static getPropsFromStores() {
        return {
            localizationStore: LocalizationStore.getState(),
            headerStore: HeaderStore.getState(),
            homeStore: HomeStore.getState(),
            ctaModalStore: CtaModalStore.getState(),
            mobile: HeaderStore.getState().mobile(),
            tablet: HeaderStore.getState().tablet(),
            msie: HeaderStore.getState().msie(),
            firefox: HeaderStore.getState().firefox()
        };
    }

    render() {
        const classes = classNames('infinityblade-wrapper', {
            mobile: this.props.mobile,
            tablet: this.props.tablet,
            msie: this.props.msie,
            firefox: this.props.firefox
        });
        const {
            location,
            history,
            ctaModalStore,
            homeStore
        } = this.props;
        const {search} = location;
        const params = qs.parse(search.replace(/\?/g, ''));
        const showModal = ctaModalStore.show || params.showModal;
        const ctaSection = homeStore.ctaSection || {};
        const {ctaItems} = ctaSection;
        const routes = getRoutes();
        const tags = getMetaTags(this.props.localizationStore);
        return (
            <div>
                <Helmet
                    meta={tags}
                    title={this.props.localizationStore.message('epic.infinityblade.name')} />
                <EpicGamesNavWrapper {...this.props} />
                <div className={classes}>
                    {routes}
                </div>
                <Footer />
                <BuyNowCTAModal
                    history={history}
                    location={location}
                    sectionData={ctaItems}
                    show={showModal} />
            </div>
        );
    }
}

export default withRouter(App);
