import React from 'react';
import classNames from 'classnames';
import CommonCarousel from '../../common/CommonCarousel';
import Video from '../../common/Video';
import PropTypes from 'prop-types';
import {isMobile, isTablet} from '../../../utils/BrowserUtils';
//import root from 'window-or-global';

export default class VideoSection extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        sectionData: PropTypes.object
    };

    static defaultProps = {
        className: '',
        sectionData: {}
    };

    omitCustomStyles = () => !isMobile() && !isTablet()

    getSlidestoShow(numberOfVideos) {
        if (!isMobile() && !isTablet()) {
            if (numberOfVideos > 2) {
                return 2;
            } else if (numberOfVideos > 3) {
                return 3;
            }
        }
        return 1;
    }

    render() {
        const {
            className,
            sectionData
        } = this.props;
        const videos = sectionData.videos || [];
        const videoLength = videos.length;
        return (
            <div className="bg-wrapper">
                <div className={classNames('carousel-gallery video-gallery', className)}>
                    <h1 className="title">Video</h1>
                    <CommonCarousel
                        className={classNames('video-carousel', {'multiple-slides': videoLength > 2 && this.omitCustomStyles()})}
                        slidesToShow={1}
                        dots={true}
                        dotsClass="custom-pag custom-dots video-dots">
                        {videos.map(video => (
                            <Video
                                key={video.description}

                                video={video.youtubeID} />
                        ))}
                    </CommonCarousel>
                </div>
            </div>
        );
    }
}
