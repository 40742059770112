import Alt from '../alt';
import Immutable from 'seamless-immutable';
import {seamlessImmutable} from 'epic-alt-utils';

@seamlessImmutable
class RecentPostStore {
    constructor() {
        this.on('error', (error) => {console.error(error);});
        this.state = Immutable({
            recentPosts: []
        });
    }
}

export default Alt.createStore(RecentPostStore, 'RecentPostStore');
