import React from 'react';
import PropTypes from 'prop-types';

export default class GradientBackground extends React.PureComponent {
    static propTypes = {
        gradient: PropTypes.string,
        top: PropTypes.string,
        bottom: PropTypes.string,
        mobile: PropTypes.bool,
        tablet: PropTypes.bool,
        image: PropTypes.bool,
        active: PropTypes.bool
    };

    render() {
        const gradientClass = `containerGradient ${this.props.gradient}`;
        const topClass = this.props.active ? `top ${this.props.top}` : `top ${this.props.top} not-visible`;
        const bottomClass = this.props.active ? `bottom ${this.props.bottom}` : `bottom ${this.props.bottom} not-visible`;
        let containerClass = gradientClass;
        const imageClass = this.props.active ? 'bot-2 active' : 'bot-2';
        if (this.props.mobile) {
            containerClass = `${gradientClass} mobile`;
        }
        if (this.props.tablet) {
            containerClass = `${gradientClass} tablet`;
        }

        return (
            <div className={containerClass}>
                <div className="texture-one" />
                <div className="sliders">
                    <div className={topClass} />
                    {this.props.image ? (<div className={imageClass} />) : ''}
                    <div className={bottomClass} />
                </div>
                <div className="texture-two" />
            </div>
        );
    }
}
