import React from 'react';
import PropTypes from 'prop-types';
import Root from 'window-or-global';
import {withRouter} from 'react-router';
import EpicGamesFooter from 'epic-games-footer/app/scripts/EpicGamesFooter';
import Immutable from 'seamless-immutable';
import {generateRoutePath} from 'epic-common/src/route';
import connectToStores from 'alt-utils/lib/connectToStores';
import {FooterStore} from '../../../stores';

@connectToStores
class Footer extends React.PureComponent {
    static propTypes = {
        footerStore: PropTypes.object,
        history: PropTypes.object,
        fullpage: PropTypes.bool
    };

    static getStores() {
        return [FooterStore];
    }

    static getPropsFromStores() {
        return {
            footerStore: FooterStore.getState()
        };
    }

    /**
     * Routes to link's href
     * @param {Object} link
     */
    addOnClick(link, history) {
        if (link && link.href) {
            link.onClick = (e) => {
                e.preventDefault();
                e.stopPropagation();
                if (link.useCustomCallback) {
                    history.push(generateRoutePath(link.href));
                } else {
                    Root.location = link.href;
                }
            };
        }
        return Immutable(link);
    }

    /**
     * Prepares the array of links for the footer
     * @param {array} links
     */
    setLinks(links) {
        links.forEach((link) => {
            this.addOnClick(link, this.props.history);
        });
        return Immutable(links);
    }

    upOnClick = (e) => {
        $.fn.fullpage.moveTo(1); //1 is the first section
    };

    render() {
        const config = this.props.footerStore.config ? this.props.footerStore.config.asMutable({deep: true}) : {};
        const fullpage = this.props.fullpage || false;
        const epicPropertyName = config.epicPropertyName || 'infinityblade';
        const links = this.setLinks(config.links || []);
        return (
            <div id="footerSection" className={`${fullpage ? 'section fp-auto-height' : ''}`} >
                <EpicGamesFooter
                    epicPropertyName={epicPropertyName}
                    links={links}
                    socialLinks={config.socialLinks || []}
                    copyrights={config.copyrights || []}
                    legalTags={config.legalTags || []}
                    logos={config.logos || []}
                    upOnClick={fullpage ? this.upOnClick : null} />
            </div>
        );
    }
}

export default withRouter(Footer);
