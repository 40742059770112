import React from 'react';
import {connectToStores} from 'epic-alt-utils/index';
import {ArticleViewWrapper} from 'epic-react-blog/app/scripts';
import AppConfigStore from '../../stores/AppConfigStore';

@connectToStores
export default class ArticleWrapper extends React.Component {
    static getStores() {
        return [AppConfigStore];
    }

    static getPropsFromStores() {
        return {};
    }

    render() {
        return (
            <div className="blog-wrapper">
                <ArticleViewWrapper
                    {...this.props}
                    rootPageSlug="news" />
            </div>
        );
    }
}
