import React from 'react';
import PropTypes from 'prop-types';
import Message from '../message/Message';

export default class BoundBox extends React.Component {
    static propTypes = {
        title: PropTypes.string,
        body: PropTypes.string,
        cta: PropTypes.string,
        active: PropTypes.bool,
        activeSlide: PropTypes.number,
        slideNumber: PropTypes.number
    };

    render() {
        const boundStyle = this.props.active && this.props.activeSlide === this.props.slideNumber ? 'boundBox active' : 'boundBox';
        return (
            <div className={boundStyle}>
                <h4 dangerouslySetInnerHTML={{__html: this.props.body}} />
                <div className="borderBox">
                    <div className="border-top" />
                    <div className="border-left" />
                    <div className="fill one" />
                    <div className="fill two" />
                    <h5>{this.props.title} | <a href={this.props.cta} target="_blank" rel="noopener noreferrer"><Message code="epic.infinityblade.news.read.more" /></a></h5>
                </div>
            </div>
        );
    }
}
