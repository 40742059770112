import React from 'react';
import PropTypes from 'prop-types';
import Message from '../message/Message';
import {generateAppPath} from 'epic-common/src/route';

const Error500 = function Error500({staticContext}) {
    if (staticContext) {
        staticContext.status = 500;
    }
    const homeLink = generateAppPath('/home');
    return (
        <div className="error500 errorPages">
            <div className="content">
                <div className="errorHeader">
                    <h1><Message code="epic.infinityblade.500.title" /></h1>
                    <h3><Message code="epic.infinityblade.500.subtitle" /></h3>
                    <div className="error-detail">
                        <Message code="epic.infinityblade.500.msg2" />
                    </div>
                    <a className="btn btn-custom buy-now-btn" href={homeLink}>
                        <Message code="epic.infinityblade.home.title" />
                    </a>
                </div>
            </div>
        </div>
    );
};

Error500.propTypes = {
    staticContext: PropTypes.object
};

export default Error500;

