import React from 'react';
import PropTypes from 'prop-types';
import Redirect from 'react-router/Redirect';
import GameInfoPageTemplate from '../../components/sections/gameinfo/GameInfoPageTemplate';
import GameInfoStore from '../../stores/GameInfoStore';
import {connectToStores} from 'epic-alt-utils/index';

const INFINITY_BLADE_I = 'infinity-blade-i';
const INFINITY_BLADE_II = 'infinity-blade-ii';
const INFINITY_BLADE_III = 'infinity-blade-iii';
const INFINITY_BLADE_UNIVERSE = 'infinity-blade-universe';

@connectToStores
export default class GameInfoView extends React.PureComponent {
    static propTypes = {
        gameInfoStore: PropTypes.object,
        match: PropTypes.object
    };
    static defaultProps = {
        gameInfoStore: {},
        match: {}
    };

    static getStores() {
        return [GameInfoStore];
    }

    static getPropsFromStores() {
        return {
            gameInfoStore: GameInfoStore.getState()
        };
    }

    render() {
        const {gameInfoStore, match} = this.props;
        const {params} = match;
        const game = (params.game || '').toLowerCase();
        const {
            infinityBladeIPageData,
            infinityBladeIIPageData,
            infinityBladeIIIPageData,
            infinityBladeUniversePageData
        } = gameInfoStore;
        switch (game) {
            case INFINITY_BLADE_I:
                return (
                    <GameInfoPageTemplate
                        pageData={infinityBladeIPageData}
                        pageKey={INFINITY_BLADE_I} />
                );
            case INFINITY_BLADE_II:
                return (
                    <GameInfoPageTemplate
                        pageData={infinityBladeIIPageData}
                        pageKey={INFINITY_BLADE_II} />
                );
            case INFINITY_BLADE_III:
                return (
                    <GameInfoPageTemplate
                        pageData={infinityBladeIIIPageData}
                        pageKey={INFINITY_BLADE_III} />
                );
            case INFINITY_BLADE_UNIVERSE:
                return (
                    <GameInfoPageTemplate
                        pageData={infinityBladeUniversePageData}
                        pageKey={INFINITY_BLADE_UNIVERSE} />
                );
            default:
                // noop
                break;
        }

        return <Redirect to="page-not-found" />;
    }
}
