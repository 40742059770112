if (window && window.__webpack_asset_path) { //eslint-disable-line
    __webpack_public_path__ = window.__webpack_asset_path; //eslint-disable-line
}

import root from 'window-or-global';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import ScrollToTopOnUpdate from 'epic-react-common/src/components/ScrollToTopOnUpdate';
import {setLocale} from 'epic-common/src/locale';
import App from './App';
import alt from './alt';
import pako from 'pako';

const reactDomEle = document.getElementById('infinityBladeReactWrapper');

// only proceed if the react ele exists
if (reactDomEle) {
    const epicState = root.__epic_client_state;
    if (epicState && typeof epicState === 'string') {
        setLocale(root.__locale);
        const preload = JSON.parse(pako.inflate(epicState.split(','), {to: 'string'}));
        alt.bootstrap(preload);
    }
    ReactDOM.hydrate(
        (
            <BrowserRouter>
                <ScrollToTopOnUpdate>
                    <App />
                </ScrollToTopOnUpdate>
            </BrowserRouter>
        ), reactDomEle
    );
}
