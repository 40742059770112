import alt from '../alt';
import Immutable from 'seamless-immutable';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils/index';
import root from 'window-or-global';

@seamlessImmutable
class AppConfigStore {
    getStateFunctions() {
        return {
            getDownloadUrl() {
                if (root._epicGamesNav && root._epicGamesNav.browser && root._epicGamesNav.browser.mac) {
                    return this.macDownloadUrl;
                }
                return this.pcDownloadUrl;
            }
        };
    }

    constructor() {
        this.on('error', (error) => {console.error(error);});
        this.on('bootstrap', seamlessBootstrap.bind(this, null));
        const initialState = Immutable({
            pcDownloadUrl: null,
            macDownloadUrl: null,
            isPrerender: false,
            isEu: false,
            euCookieMessage: {}
        });
        this.state = initialState.merge(this.getStateFunctions());
    }
}

export default alt.createStore(AppConfigStore, 'AppConfigStore');
