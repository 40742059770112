import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DropDownList from './DropDownList';

export default class GameManualSection extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        pageKey: PropTypes.string,
        sectionData: PropTypes.object,
        localizationStore: PropTypes.object
    };
    
    static defaultProps = {
        className: '',
        pageKey: '',
        sectionData: {},
        localizationStore: {}
    };

    render() {
        const {
            className,
            localizationStore,
            sectionData,
            pageKey
        } = this.props;
        const {messages} = localizationStore;
        const sectionTitle = sectionData.sectionTitle || messages['epic.infinityblade.game_info.game.manual.title'];
        const gameManuals = sectionData.gameManuals || [];
        return (
            <div id="game-manual" className={classNames('game-manual', className)}>
                <div className="main-title-section">
                    <h1 className="title">
                        <span className="highlight">{sectionTitle}</span>
                    </h1>
                </div>
                <div className="info-section">
                    <div className="divider" />
                    {gameManuals.map((data) => {
                        const subListItems = data.subListItems || [];
                        const hasSubListItems = subListItems.length > 0;
                        return hasSubListItems ? (
                            <DropDownList
                                key={`${data.title}-${pageKey}`}
                                className={data.className}
                                title={data.title}
                                description={data.description}>
                                {subListItems.map(subItem => (
                                    <DropDownList
                                        key={`${subItem.title}-${pageKey}`}
                                        className={subItem.className}
                                        subList={true}
                                        title={subItem.title}
                                        description={subItem.description} />
                                ))}
                            </DropDownList>
                        ) : (
                            <DropDownList
                                key={`${data.title}-${pageKey}`}
                                className={data.className}
                                title={data.title}
                                description={data.description} />
                        );
                    })}
                </div>
            </div>
        );
    }
}
