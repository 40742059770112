import alt from '../alt';
import generateWithAsyncActions from 'epic-alt-utils';

/**
 * FullpageActions
 */
export default generateWithAsyncActions(alt, [], [
    'setLoadingSection',
    'setLoadingSlide',
    'setLoaded'
]);
