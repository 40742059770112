import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import NewsSection from './NewsSection';
import Message from '../../message/Message';
import SocialShareIcon from '../../common/SocialShareIcon';

export default class CommunitySection extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        sectionData: PropTypes.object
    };

    static defaultProps = {
        className: '',
        sectionData: {}
    };

    render() {
        const {className} = this.props;
        const communityData = this.props.sectionData;
        const socialLinks = communityData.socialLinks || [];
        return (
            <div
                className={classNames('section community', className)}>
                <NewsSection />
                <div className="common-section connect-section">
                    <div className="connect-container">
                        <h1 className="connect-title">
                            <Message
                                className="highlight"
                                code="epic.infinityblade.home.community.connect_with_us" />
                        </h1>
                        <ul className="social-share-list">
                            {socialLinks.map(item => (
                                <SocialShareIcon
                                    key={item.id}
                                    type={item.id}
                                    link={item.href} />
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
