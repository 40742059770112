import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CollapseItem from './CollapseItem';
//import root from 'window-or-global';

export default class HighlightedFeatures extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        sectionData: PropTypes.object,
        localizationStore: PropTypes.object
    };
    static defaultProps = {
        className: '',
        sectionData: {},
        localizationStore: {}
    };
    render() {
        const {className, sectionData, localizationStore} = this.props;
        const messages = localizationStore.messages || {};
        const features = sectionData.highlightedFeatures || [];
        const sectionTitle = sectionData.sectionTitle || messages['epic.infinityblade.game_info.features'];
        return (
            <div className={classNames('features', className)}>
                <div className="title-section">
                    <h1 className="title">
                        <span className="highlight">{sectionTitle}</span>
                    </h1>
                </div>
                {features.map((data) => {
                    const descriptionSection = data.descriptionSection || {};
                    const backgroundStyle = {
                        backgroundImage: `url(${data.backgroundImage})`
                    };
                    return (
                        <CollapseItem
                            key={data.title}
                            className={classNames('highlighted-feature', className)}
                            title={data.title}
                            descriptionTitle={descriptionSection.title}
                            description={descriptionSection.description}
                            backgroundStyle={backgroundStyle} />
                    );
                })}
            </div>
        );
    }
}
