import alt from '../alt';
import Immutable from 'seamless-immutable';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils/index';
import CtaModalActions from '../actions/CtaModalActions';

@seamlessImmutable
class CtaModalStore {
    initialState = Immutable({
        show: false
    })

    constructor() {
        this.on('error', (error) => {console.error(error);});
        this.on('bootstrap', seamlessBootstrap.bind(this, null));

        this.state = this.initialState;
        this.bindActions(CtaModalActions);
    }

    toggleModal() {
        this.setState(this.state.merge({
            show: !this.state.show
        }, {deep: true}));
    }

    closeModal() {
        this.setState(this.state.merge({
            show: false
        }, {deep: true}));
    }

    openModal() {
        this.setState(this.state.merge({
            show: true
        }, {deep: true}));
    }
}

export default alt.createStore(CtaModalStore, 'CtaModalStore');
