import alt from '../alt';
import Immutable from 'seamless-immutable';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils/index';

@seamlessImmutable
class EulaStore {
    constructor() {
        this.on('error', (error) => {console.error(error);});
        this.on('bootstrap', seamlessBootstrap.bind(this, null));
        this.state = Immutable({
            eulaContent: null,
            errorMessage: null
        });
    }
}

export default alt.createStore(EulaStore, 'EulaStore');
