import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CommonCarousel from '../../common/CommonCarousel';

export default class TestimonialSection extends React.PureComponent {
    state = {
        backgroundImage: ''
    }
    static propTypes = {
        className: PropTypes.string,
        sectionData: PropTypes.object
    };

    static defaultProps = {
        className: '',
        sectionData: {}
    };

    componentWillMount() {
        const hasInitialBackgroundImage = this.state.backgroundImage;
        const {testimonials} = this.props.sectionData;
        const targetTestimonial = testimonials[0]; // Use backgroundImage of first Item
        if (!hasInitialBackgroundImage) {
            this.setState({
                backgroundImage: targetTestimonial.backgroundImage
            });
        }
    }

    render() {
        const {className, sectionData} = this.props;
        const {backgroundImage} = sectionData;
        const bgImageStyle = {
            backgroundImage: `url(${backgroundImage})`
        };
        const testimonials = sectionData.testimonials || [];
        return (
            <div
                className={classNames('section testimonial-section', className)}
                style={bgImageStyle}>
                <CommonCarousel
                    className="testimonial-carousel"
                    dots={true}
                    dotsClass="custom-pag custom-dots testimonial-dots"
                    infinite={true}
                    autoplay={true}
                    variableWidth={false}
                    autoplaySpeed={4500}>
                    {testimonials.map((data) => {
                        const {rating} = data;
                        const fillSpace = !rating; // If no rating exists then fill the space
                        return (
                            <div
                                key={`${data.rating}-${data.company}`}
                                className="testimonial-container">
                                <div className={classNames('text-section', {fill: fillSpace})}>
                                    <div className="rating-section">
                                        <h1 className="rating-number">{data.rating}</h1>
                                    </div>
                                    <h1
                                        className="text"
                                        dangerouslySetInnerHTML={{__html: data.testimonial}} />
                                    <p className="company">{data.company}</p>
                                </div>
                            </div>
                        );
                    })}
                </CommonCarousel>
            </div>
        );
    }
}
