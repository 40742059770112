import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {connectToStores} from 'epic-alt-utils/index';
import RecentPostsSection from './RecentPostsSection';
import LocalizationStore from '../../../stores/LocalizationStore';

@connectToStores
class NewsSection extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        localizationStore: PropTypes.object
    };

    static defaultProps = {
        className: '',
        localizationStore: {}
    };

    static getStores() {
        return [LocalizationStore];
    }

    static getPropsFromStores() {
        return {
            localizationStore: LocalizationStore.getState()
        };
    }

    render() {
        const {className, localizationStore} = this.props;
        const {messages} = localizationStore;
        return (
            <div
                className={classNames('section news-section', className)}>
                <div className="news-title">
                    <h1 className="text">
                        <span className="highlight">{messages['epic.infinityblade.home.news_section.title']}</span>
                    </h1>
                </div>
                <div className="news-wrapper">
                    <RecentPostsSection />
                </div>
            </div>
        );
    }
}

export default NewsSection;
