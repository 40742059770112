import React from 'react';
import Message from '../message/Message';
import PropTypes from 'prop-types';
import {generateAppPath} from 'epic-common/src/route';

const Error404 = function Error404({staticContext}) {
    if (staticContext) {
        staticContext.status = 404;
    }
    const homeLink = generateAppPath('/home');
    return (
        <div className="error404 errorPages">
            <div className="content">
                <div className="errorHeader">
                    <h1><Message code="epic.infinityblade.404" /></h1>
                    <h3><Message code="epic.infinityblade.404.subtitle" /></h3>
                    <a className="btn btn-custom buy-now-btn" href={homeLink}>
                        <Message code="epic.infinityblade.home.title" />
                    </a>
                </div>
            </div>
        </div>
    );
};

Error404.propTypes = {
    staticContext: PropTypes.object
};

export default Error404;
