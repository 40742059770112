import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
//import root from 'window-or-global';

const iconSet = {
    chevron: {
        active: 'icon-chevron-down',
        nonActive: 'icon-chevron-down'
    },
    square: {
        active: 'icon-plus-square',
        nonActive: 'icon-minus-square'
    }
};

export default class DropDownList extends React.Component {
    state = {
        active: false
    };
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string,
        // Nested list (with different styles)
        subList: PropTypes.bool,
        children: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),
        description: PropTypes.string
    };
    static defaultProps = {
        className: '',
        title: '',
        subList: false,
        children: [],
        description: ''
    };

    toggle = () => {
        this.setState({
            active: !this.state.active
        });
    };

    render() {
        const {
            className,
            children,
            title,
            description,
            subList
        } = this.props;
        const {active} = this.state;
        const style = active ? {} : {
            height: 0,
            padding: 0,
            border: 'none'
        };
        const activeClass = {active};
        const iconType = subList ? iconSet.chevron : iconSet.square;
        return (
            <div className={classNames('list-dropdown-container', className, subList ? 'sub-list' : '')}>
                <div className={classNames('list-dropdown', activeClass)}>
                    <button className="toggle-btn" onClick={this.toggle}>
                        <div className="title-section">
                            <h1 className={classNames('drop-down-title', activeClass)}>
                                {title}
                            </h1>
                        </div>
                        <div className="toggle-section">
                            <span
                                className={classNames('icon', active ? iconType.nonActive : iconType.active, activeClass)} />
                        </div>
                    </button>
                </div>
                <div className="content" style={style}>
                    {description && (
                        <div className="description" dangerouslySetInnerHTML={{__html: description}} />
                    )}
                    {children}
                </div>
            </div>
        );
    }
}
