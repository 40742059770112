import React from 'react';
//import classNames from 'classnames';
//import root from 'window-or-global';
//import PropTypes from 'prop-types';

import DropDownList from '../../components/sections/gameinfo/DropDownList';

export default class GameInfoView extends React.Component {
    render() {
        return (
            <div id="game-manual" className="game-manual">
                <h1 className="page-title">
                    <span className="highlight">
                        Game manual page
                    </span>
                </h1>
                <div className="info-section">
                    <div className="divider" />
                    <DropDownList
                        title="Intro"
                        description="Intro description goes here" />
                    <DropDownList
                        title="Story"
                        description="Story description goes here">
                        <DropDownList
                            subList={true}
                            iconType="chevron-down"
                            title="Styles"
                            description="Description of the styles here">
                            <ul className="sub-content">
                                <li> item 1 - asdfasdf </li>
                                <li> item 2 - asdfasdf </li>
                            </ul>
                        </DropDownList>
                    </DropDownList>
                </div>
            </div>
        );
    }
}
