import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class BoxArtifact extends React.Component {
    static propTypes = {
        right: PropTypes.bool,
        reset: PropTypes.bool,
        ready: PropTypes.bool,
        finalPosition: PropTypes.number,
        top: PropTypes.number,
        width: PropTypes.number,
        zIndex: PropTypes.number,
        color: PropTypes.string
    };

    state = {
        moved: false
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.ready && !this.state.moved) {
            setTimeout(() => {
                this.setState({
                    moved: true
                });
            }, 600);
        }
        if (nextProps.reset && this.state.moved) {
            this.setState({
                moved: false
            });
        }
    }

    render() {
        const right = this.props.right || false;
        const classes = classNames('box-artifact', {right, left: !right, moved: this.state.moved});
        const style = {
            top: `${this.props.top}%` || '50%',
            backgroundColor: this.props.color || 'white'
        };

        if (this.props.width) {
            style.width = this.props.width;
        }

        if (this.props.zIndex) {
            style.zIndex = this.props.zIndex;
        }

        if (this.state.moved) {
            const final = `${this.props.finalPosition}%` || '50%';
            if (right) {
                style.right = final;
            } else {
                style.left = final;
            }
        }

        return (
            <div className={classes} style={style} />
        );
    }
}
