import React from 'react'; //eslint-disable-line no-unused-vars
import Route from 'react-router/Route';
import Redirect from 'react-router/Redirect';
import Switch from 'react-router/Switch';
import HomeView from '../views/ib/HomeView';
import GameInfoView from '../views/ib/GameInfoView';
import GameManualView from '../views/ib/GameManualView';
import NewsWrapper from '../views/news/NewsWrapper';
import ArticleWrapper from '../views/news/ArticleWrapper';
import {Error404, Error500} from '../components';
import {getPath} from 'epic-common/src/route';

const redirectLocale = (destination, route) => {
    const path = getPath();
    let destPath = null;
    if (typeof destination === 'function') {
        destPath = destination(route.match.params);
    } else {
        destPath = destination;
    }
    const redirectUrl = `${path}${route.match.params.locale}${destPath}${route.location.search || ''}`;
    return (
        <Redirect to={redirectUrl} />
    );
};
const goHome = redirectLocale.bind(null, '/home');

const getRoutes = function getRoutes() {
    const path = getPath();
    const routes = (
        <Switch>
            <Route exact path={`${path}:locale/`} render={goHome} />
            <Route path={`${path}:locale/home`} component={HomeView} />
            <Route path={`${path}:locale/game-info/manual`} component={GameManualView} />
            <Route path={`${path}:locale/game-info/:game`} component={GameInfoView} />
            <Route exact path={`${path}:locale/news`} component={NewsWrapper} />
            <Route path={`${path}:locale/news/category/:category`} component={NewsWrapper} />
            <Route path={`${path}:locale/news/:title`} component={ArticleWrapper} />
            <Route path={`${path}:locale/page-not-found`} component={Error404} />
            <Route path={`${path}:locale/unexpected-error`} component={Error500} />
            <Route component={Error404} />
        </Switch>
    );
    return routes;
};

export default getRoutes;
