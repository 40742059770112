import {generateRoutePath} from 'epic-common/src/route';
import root from 'window-or-global';
import SocialShareImage from '../../../images/ib-share.png';

export function getAssetPath(asset) {
    return `${root.__webpack_asset_path || ''}${asset}`;
}

export function trackProgress() {
    if (typeof root.com.epicgames !== 'undefined' && typeof root.com.epicgames.web !== 'undefined' && !!root.com.epicgames.web.track) {
        root.com.epicgames.web.track.loadPixel();
    }
}

export function loadScript(url) {
    if (typeof document === 'undefined') {
        console.log('FortniteUtils loadScript skipped, document not available');
        return;
    }
    const scriptTag = document.createElement('script');
    scriptTag.setAttribute('type', 'text/javascript');
    scriptTag.setAttribute('src', url);
    document.head.appendChild(scriptTag);
}

export function canPlayVideo() {
    if (typeof document === 'undefined') {
        console.log('FortniteUtils canPlayVideo: false, document not available');
        return false;
    }
    let canPlay;
    const video = document.createElement('video');
    if (video.canPlayType && video.canPlayType('video/webm') !== '') {
        canPlay = 'webm';
    } else if (video.canPlayType && video.canPlayType('video/mp4') !== '') {
        canPlay = 'mp4';
    } else {
        canPlay = 'none';
    }
    return canPlay;
}

export function getMetaTags(localizationStore) {
    const {locale} = localizationStore;
    // Social metadata
    const socialMeta = {
        siteName: localizationStore.messages['epic.infinityblade.name'],
        description: localizationStore.messages['epic.infinityblade.common.description'],
        twitterDesc: localizationStore.messages['epic.infinityblade.common.description'],
        locale: locale ? locale.replace('-', '_') : locale
    };

    let baseUrl;
    if (typeof document === 'undefined') {
        baseUrl = root.location.origin;
    } else {
        baseUrl = document.getElementsByTagName('html')[0].dataset
            ? document.getElementsByTagName('html')[0].dataset.baseurl
            : document.getElementsByTagName('html')[0].getAttribute('data-baseurl');
    }

    //if ends with /infinityblade add a slash
    if (!baseUrl.match(/.*\/$/)) {
        baseUrl += '/';
    }

    const ogUrl = root.location.href;

    const tags = [
        //Facebook
        {property: 'og:url', content: ogUrl},
        {property: 'og:title', content: socialMeta.siteName},
        {property: 'og:description', content: socialMeta.description},
        {property: 'og:site_name', content: socialMeta.siteName},
        {property: 'og:locale', content: socialMeta.locale},
        {property: 'og:image', content: getAssetPath(SocialShareImage)},

        //Twitter
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:title', content: socialMeta.siteName},
        {name: 'twitter:description', content: socialMeta.twitterDesc},
        {name: 'twitter:image', content: getAssetPath(SocialShareImage)}
    ];
    return tags;
}

export function getDateParams(input) {
    const date = new Date(input);
    const time = date.getTime();
    const year = date.getFullYear();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const month = date.getMonth();
    const enMonth = months[month + 1];
    const day = date.getDate() < 9 ? `0${date.getDate()}` : date.getDate();
    const hour = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();


    return {
        date, time, year, month, enMonth, day, hour, min, sec
    };
}

export function parseHTML(string) {
    const html = document.createElement('div');
    html.innerHTML = string;
    return html;
}

/**
 * If the delta for the x or y coordinates of the mouse have changed from mouse down to mouse up
 *  it is dragging motion and not intended to be a click.
 * @param  {Object} e Event object
 * @return {Boolean}   true/false
 */
export function draggingMotion(e, mouseDown) {
    const allowed = 40;
    const x = e.clientX;
    const y = e.clientY;

    if (mouseDown) {
        if (Math.abs(x - mouseDown.x) > allowed) {
            return true;
        }

        if (Math.abs(y - mouseDown.y) > allowed) {
            return true;
        }
    }

    return false;
}

export function getSurvivorNavLink(isLoggedIn, commander) {
    if (isLoggedIn && commander) {
        return generateRoutePath('/search-for-survivors/rewards', true);
    }
    return generateRoutePath('/search-for-survivors', true);
}

/**
 * Fisher-Yates shuffle function.  This shuffle is done in-place and is destructive
 * @param array
 */
export function shuffle(array) {
    let unShuffled = array.length;
    let element = null;
    let currentElement;

    // While there remain elements to shuffle…
    while (unShuffled) {
        // Pick a remaining element…
        currentElement = Math.floor(Math.random() * unShuffled--);

        // And swap it with the current element.
        element = array[unShuffled];
        array[unShuffled] = array[currentElement];
        array[currentElement] = element;
    }

    return array;
}
