import React from 'react';
import PropTypes from 'prop-types';
import {connectToStores} from 'epic-alt-utils/index';
import {BlogView} from 'epic-react-blog/app/scripts';
import AppConfigStore from '../../stores/AppConfigStore';

@connectToStores
export default class NewsWrapper extends React.Component {
    static getStores() {
        return [AppConfigStore];
    }

    static getPropsFromStores() {
        return {
            alertMessage: AppConfigStore.getState().globalAlert
        };
    }
    static propTypes = {
        alertMessage: PropTypes.object //eslint-disable-line
    };

    render() {
        return (
            <div className="blog-wrapper">
                <BlogView
                    {...this.props}
                    enableForums={false}
                    enableAutoShortDescription={true}
                    rootPageSlug="news" />
            </div>
        );
    }
}
