import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
//import root from 'window-or-global';

export default class GamesSection extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        sectionData: PropTypes.object,
        localizationStore: PropTypes.object,
        enableAvailableForSection: PropTypes.bool
    };

    static defaultProps = {
        className: '',
        sectionData: {},
        localizationStore: {},
        enableAvailableForSection: true
    };

    generateUniqueKey = () => Math.round(Math.random() * 100)

    render() {
        const {
            className,
            enableAvailableForSection,
            sectionData,
            localizationStore
        } = this.props;
        const messages = localizationStore.messages || {};
        const game = sectionData; // Keep it consistent
        const gameBodyBackgroundStyle = {
            backgroundImage: `url(${game.backgroundImage})`,
            backgroundRepeat: 'no-repeat'
        };
        return (
            <div className={classNames('games', className)} style={gameBodyBackgroundStyle}>
                <div className="games-body">
                    <div className="content-container">
                        <div className="title-section">
                            <h1 className="title">{game.title}</h1>
                        </div>
                        <div className="description-section">
                            <p className="description">{game.description}</p>
                        </div>
                        <div className="phrase-section">
                            <p className="phrase">{game.phrasePartOne}</p>
                            <p className="phrase">{game.phrasePartTwo}</p>
                        </div>
                        {enableAvailableForSection && (
                            <div className="device-compatibility-section">
                                <div>
                                    <p className="bold">{messages['epic.infinityblade.game_info.available_for']}</p>
                                    <div
                                        className="device-text"
                                        dangerouslySetInnerHTML={{__html: game.deviceAvailability}} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
