import React from 'react';
import PropTypes from 'prop-types';
import GallerySection from './GallerySection';
import VideoSection from './VideoSection';
import GamesSection from './GamesSection';
import HighlightedFeatures from './HighlightedFeatures';
import GameManualSection from './GameManualSection';
import AdditionalFeatures from './AdditionalFeatures';
import LocalizationStore from '../../../stores/LocalizationStore';
import {connectToStores} from 'epic-alt-utils/index';

@connectToStores
class GameInfoPageTemplate extends React.PureComponent {
    static propTypes = {
        pageData: PropTypes.object,
        localizationStore: PropTypes.object,
        pageKey: PropTypes.string
    };

    static defaultProps = {
        pageData: {},
        localizationStore: {},
        pageKey: ''
    };

    static getStores() {
        return [LocalizationStore];
    }

    static getPropsFromStores() {
        return {
            localizationStore: LocalizationStore.getState()
        };
    }

    render() {
        const {localizationStore, pageData, pageKey} = this.props;
        const {
            highlightedFeatureSection,
            gallerySection,
            gameSection,
            videoSection,
            gameManualSection,
            additionalFeaturesSection
        } = pageData;
        const config = pageData.config || {};
        return (
            <div id="game-info" className="game-info-view">
                {config.enableGameSection && (
                    <GamesSection
                        localizationStore={localizationStore}
                        enableAvailableForSection={config.enableAvailableForSection}
                        sectionData={gameSection} />
                )}
                <div className="game-info-view-container">
                    {config.enableHighlightedFeatureSection && (
                        <HighlightedFeatures
                            localizationStore={localizationStore}
                            sectionData={highlightedFeatureSection} />
                    )}
                    {config.enableAdditionalFeaturesSection && (
                        <AdditionalFeatures
                            sectionData={additionalFeaturesSection} />
                    )}
                    {config.enableVideoSection && (
                        <VideoSection
                            sectionData={videoSection} />
                    )}
                    {config.enableGallerySection && (
                        <GallerySection
                            sectionData={gallerySection} />
                    )}
                    {config.enableGameManualSection && (
                        <GameManualSection
                            localizationStore={localizationStore}
                            sectionData={gameManualSection}
                            pageKey={pageKey} />
                    )}
                </div>
            </div>
        );
    }
}

export default GameInfoPageTemplate;
