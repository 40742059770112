import root from 'window-or-global';

export function isMobile() {
    const epicGamesNavInfo = root._epicGamesNav;
    const {browser} = epicGamesNavInfo;
    return browser && browser.mobile;
}

export function isTablet() {
    const epicGamesNavInfo = root._epicGamesNav;
    const {browser} = epicGamesNavInfo;
    return browser && browser.tablet;
}
