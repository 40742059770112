import alt from '../alt';
import Immutable from 'seamless-immutable';
import {seamlessImmutable} from 'epic-alt-utils/index';

@seamlessImmutable
class FooterStore {
    constructor() {
        this.on('error', (error) => {console.error(error);});
        this.state = Immutable({
            config: null
        });
    }
}

export default alt.createStore(FooterStore, 'FooterStore');
