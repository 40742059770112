import alt from '../alt';
import root from 'window-or-global';
import Immutable from 'seamless-immutable';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils/index';

@seamlessImmutable
class HeaderStore {
    getStateFunctions() {
        return {
            mobile() {
                return root._epicGamesNav ? root._epicGamesNav.browser.mobile : false;
            },
            tablet() {
                return root._epicGamesNav ? root._epicGamesNav.browser.tablet : false;
            },
            msie() {
                return root._epicGamesNav ? root._epicGamesNav.browser.msie : false;
            },
            firefox() {
                return root._epicGamesNav ? root._epicGamesNav.browser.firefox : false;
            }
        };
    }

    constructor() {
        this.on('error', (error) => {console.error(error);});
        this.on('bootstrap', seamlessBootstrap.bind(this, null));
        const initialState = this.getStateFunctions();
        initialState.config = null;
        this.state = Immutable(initialState);
    }
}

export default alt.createStore(HeaderStore, 'HeaderStore');
