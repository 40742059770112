import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
//import root from 'window-or-global';

export default class CollapseItem extends React.Component {
    state = {
        isHidden: true
    };
    static propTypes = {
        className: PropTypes.string,
        title: PropTypes.string,
        descriptionTitle: PropTypes.string,
        description: PropTypes.string,
        backgroundStyle: PropTypes.object
    };
    static defaultProps = {
        className: '',
        title: '',
        descriptionTitle: '',
        description: '',
        backgroundStyle: {}
    };

    toggleContainerDisplay = () => {
        this.setState({
            isHidden: !this.state.isHidden
        });
    }
    render() {
        const {
            className,
            title,
            descriptionTitle,
            description,
            backgroundStyle
        } = this.props;
        const {isHidden} = this.state;
        const displayContainerStyle = isHidden ? {height: 0} : {};
        const activeClass = {active: !isHidden};
        return (
            <div role="presentation" id="collapse-item" className={classNames('collapse-item', className)} onClick={this.toggleContainerDisplay}>
                <div className={classNames('bg-container', activeClass)} style={backgroundStyle}>
                    <button className="open-btn">
                        <h1 className="collapse-title icon">
                            <span
                                className={classNames('custom-icon icon-chevron-right', activeClass)} />
                        </h1>
                        <h1 className="collapse-title text">
                            <span className="text">
                                {title}
                            </span>
                        </h1>
                    </button>
                </div>
                <div
                    className={classNames('display-container')}
                    style={displayContainerStyle}>
                    <div className="display-title-section">
                        <h1 className="title">
                            <span className="highlight">
                                {descriptionTitle}
                            </span>
                        </h1>
                    </div>
                    <div className="description-section">
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{__html: description}} />
                    </div>
                </div>
            </div>
        );
    }
}
