import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Message from '../../message/Message';

export default class AdditionalFeatures extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        sectionData: PropTypes.object
    };
    
    static defaultProps = {
        className: '',
        sectionData: {}
    };

    render() {
        const {
            className,
            sectionData
        } = this.props;
        const {description} = sectionData;
        return (
            <div className={classNames('additional-features', className)}>
                <div className="title-section">
                    <h1 className="title">
                        <Message code="epic.infinityblade.additionalFeatures" />
                    </h1>
                </div>
                <div className="feature">
                    <div className="description-section">
                        <div className="description" dangerouslySetInnerHTML={{__html: description}} />
                    </div>
                </div>
            </div>
        );
    }
}
