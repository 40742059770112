import React from 'react';
import PropTypes from 'prop-types';
import CSSTransition from 'react-transition-group/CSSTransition';

export default class FadeIn extends React.PureComponent {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array
        ]),
        classNames: PropTypes.string,
        appear: PropTypes.bool
    };

    static defaultProps = {
        classNames: 'fade-in',
        appear: false
    }

    render() {
        const children = this.props.children || null;

        return (
            <CSSTransition
                {...this.props}
                timeout={{
                    enter: 600,
                    exit: 1000
                }}>
                {children}
            </CSSTransition>
        );
    }
}
