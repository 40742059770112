import Alt from '../alt';
import Immutable from 'seamless-immutable';
import {seamlessImmutable} from 'epic-alt-utils';
import {FullpageActions} from '../actions';

@seamlessImmutable
class FullpageStore {
    constructor() {
        this.on('error', (error) => {console.error(error);});

        this.state = Immutable({
            loadingSection: 1, //the section which is loading/animating and will become active
            loadingSlides: {}, //map of slides which is loading/animating and will become active
            fullPageLoaded: false //page loads
        });

        this.bindActions(FullpageActions);
    }

    onSetLoadingSection(sectionNum) {
        if (sectionNum) {
            this.setState(this.state.merge({
                loadingSection: sectionNum
            }));
        }
    }

    onSetLoadingSlide({sectionNum, slideNum}) {
        const loadingSlides = this.state.loadingSlides.asMutable();
        loadingSlides[sectionNum] = slideNum;
        this.setState(this.state.merge({
            loadingSlides
        }));
    }

    onSetLoaded() {
        this.setState(this.state.merge({
            fullPageLoaded: true
        }));
    }
}

export default Alt.createStore(FullpageStore, 'FullpageStore');
