import './common/button.sass';
import './common/commonCarousel.sass';
import './common/animations/fadeIn.sass';

import './sections/home/testimonialSection.sass';
import './sections/home/buyNowSection.sass';
import './sections/home/gameInfoSection.sass';
import './sections/home/newsSection.sass';
import './sections/home/communitySection.sass';
import './sections/home/buyNowCTAModal.sass';
import './sections/home/buyNowGameItems.sass';
import './sections/home/recentPostsWrapper.sass';

import './sections/gameinfo/videoSection.sass';
import './sections/gameinfo/gallerySection.sass';
import './sections/gameinfo/collapseItem.sass';
import './sections/gameinfo/dropDownList.sass';
import './sections/gameinfo/dropDownListItemCustomStyles.sass';
import './sections/gameinfo/highlightedFeatures.sass';
import './sections/gameinfo/gamesSection.sass';
import './sections/gameinfo/gameManualSection.sass';
import './sections/gameinfo/additionalFeatures.sass';

/* Custom nav styles */
import './nav/epicGamesNavWrapper.sass';

/* Custom footer styles */
import './sections/footer/footer.sass';
import './error/error.sass';
