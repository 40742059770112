import React from 'react';
import PropTypes from 'prop-types';
import SlickWrapper from 'epic-react-common/src/components/SlickWrapper';
import classNames from 'classnames';

export default class CommonCarousel extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        dotsClass: PropTypes.string,
        autoplay: PropTypes.bool,
        dots: PropTypes.bool,
        centerMode: PropTypes.bool,
        variableWidth: PropTypes.bool,
        autoplaySpeed: PropTypes.number,
        infinite: PropTypes.bool,
        slidesToShow: PropTypes.number,
        children: PropTypes.array,
        includeCallBack: PropTypes.bool,
        afterChange: PropTypes.func
    };
    static defaultProps = {
        className: '',
        dotsClass: '',
        autoplay: false,
        autoplaySpeed: 2500,
        slidesToShow: 1,
        infinite: false,
        children: [],
        includeCallBack: false,
        afterChange: () => {},
        centerMode: false,
        variableWidth: true
    };
    getSliderOptions = () => {
        const {
            dots,
            dotsClass,
            autoplay,
            autoplaySpeed,
            slidesToShow,
            infinite,
            includeCallBack,
            afterChange,
            centerMode,
            variableWidth
        } = this.props;

        const config = {
            infinite,
            variableWidth,
            speed: 400,
            draggable: true,
            touchMove: true,
            focusOnSelect: false,
            slidesToScroll: 1,
            slidesToShow,
            dots,
            dotsClass,
            autoplay,
            autoplaySpeed,
            centerMode
        };

        /* Responsive configuration*/
        config.responsive = [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1,
                    dots: false
                }
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 1,
                    dots: false
                }
            }
        ];

        if (dots) {
            config.customPaging = i => (<div key={i} className="dots" />);
        }

        if (includeCallBack) {
            config.afterChange = afterChange;
        }

        return config;
    }
    render() {
        const {children, className} = this.props;
        return (
            <SlickWrapper
                className={classNames('carousel', className)}
                options={this.getSliderOptions()}>
                {children}
            </SlickWrapper>
        );
    }
}
