/**
 * components/index.js
 *
 * All components are listed here.
 */

export {default as EpicGamesNavWrapper} from './nav/EpicGamesNavWrapper';
export {default as Message} from './message/Message';
export {default as GradientBackground} from './sections/background/GradientBackground';
export {default as BoxArtifact} from './common/BoxArtifact';
export {default as BoundBox} from './common/BoundBox';
export {default as Footer} from './sections/footer/Footer';
export {default as Error404} from './error/Error404';
export {default as Error500} from './error/Error500';
export {default as Video} from './common/Video';

//export {default as Error500} from './error/Error500';
