import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import RecentPosts from 'epic-react-blog/app/scripts/components/blog/recentPosts/index';
import {connectToStores} from 'epic-alt-utils/index';
import RecentPostStore from '../../../stores/RecentPostStore';
import {isMobile, isTablet} from '../../../utils/BrowserUtils';

@connectToStores
class RecentPostsWrapper extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        recentPostStore: PropTypes.object
    };

    static defaultProps = {
        className: '',
        recentPostStore: {}
    };

    static getStores() {
        return [RecentPostStore];
    }

    static getPropsFromStores() {
        return {
            recentPostStore: RecentPostStore.getState()
        };
    }

    render() {
        const {className, recentPostStore} = this.props;
        return (
            <div id="recent-post-wrapper" className={classNames('recent-post-wrapper', className)}>
                <RecentPosts.FourColumn
                    maxRowSize={4}
                    routerRootUrl="news"
                    enableTitle={false}
                    hideCategoryLink={true}
                    blogs={recentPostStore.recentPosts}
                    useTitleCase={false}
                    isMobile={isMobile()}
                    isTablet={isTablet()} />
            </div>
        );
    }
}

export default RecentPostsWrapper;
