import alt from '../alt';
import Immutable from 'seamless-immutable';
import {LoginActions} from '../actions';
import {LocalizationStore} from '../stores';
import {seamlessImmutable, seamlessBootstrap} from 'epic-alt-utils/index';
import {processParams} from 'epic-common/src/url';
import root from 'window-or-global';

@seamlessImmutable
class LoginStore {
    constructor() {
        this.on('error', (error) => {console.error(error);});
        this.on('bootstrap', seamlessBootstrap.bind(this, null));
        this.state = Immutable({
            isLoggedIn: false,
            accountInfo: null
        });
        this.bindListeners({
            handleLoginError: []
        });
        this.bindActions(LoginActions);
    }

    handleLoginError(response) {
        if (response.status === '401') {
            this.setState(this.state.merge({
                isLoggedIn: false
            }));
        } else {
            console.log(response);
        }
    }

    handleLogout() {
        // set login to false if login was true
        this.setState(this.state.merge({
            isLoggedIn: false
        }));
    }

    loginListener(event) {
        // refresh current page if login Status has been changed
        if (event.key === 'login' && event.newValue !== event.oldValue) {
            let loggedInValue = event.newValue;
            // Fix for IE storing values as strings rather than bools
            if (typeof loggedInValue === 'string') {
                loggedInValue = loggedInValue === 'true';
            }
            // Fix for IE this is always fired immediately upon loading the page so only act on it
            // if the value is not what we expect
            if (this.state.isLoggedIn !== loggedInValue) {
                this.setState(this.state.set('isLoggedIn', loggedInValue));
                setImmediate(root.location.reload.bind(root.location));
            }
        }
    }

    onRequestLogin(returnPath) {
        const {messages} = LocalizationStore.state;
        const regMsg = messages['epic.orion.accountportal.default.register'];
        const loginMsg = messages['epic.orion.accountportal.default.sign_in'];
        const loginParams = {
            regSubheading: regMsg,
            loginSubheading: loginMsg,
            state: returnPath || '/'
        };
        const loginUrl = `${root.appContext || ''}/login?${processParams(loginParams)}`;
        root.location.href = loginUrl;
    }
}

export default alt.createStore(LoginStore, 'LoginStore');
