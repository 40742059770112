import React from 'react';
import classNames from 'classnames';
import CommonCarousel from '../../common/CommonCarousel';
import PropTypes from 'prop-types';
import Message from '../../message/Message';
//import { isMobile } from '../../../utils/BrowserUtils';
//import root from 'window-or-global';

export default class GallerySection extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        sectionData: PropTypes.object
    };

    static defaultProps = {
        className: '',
        sectionData: {}
    };

    render() {
        const {
            className,
            sectionData
        } = this.props;
        const {images} = sectionData;
        return (
            <div className={classNames('carousel-gallery image-gallery', className)}>
                <h1 className="title">
                    <Message code="epic.infinityblade.screenshots.title" />
                </h1>
                <CommonCarousel
                    className="gallery-carousel"
                    slidesToShow={1}
                    variableWidth
                    dots={true}
                    dotsClass="custom-pag custom-dots gallery-dots">
                    {images && images.map((image, index) => {
                        const uniqueKey = `Gallery-${index}`;
                        return (
                            <img
                                key={uniqueKey}
                                className="gallery"
                                alt="gallery item"
                                src={image} />
                        );
                    })}
                </CommonCarousel>
            </div>
        );
    }
}
