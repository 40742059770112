import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class BuyNowGameItem extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        games: PropTypes.array
    };

    static defaultProps = {
        className: '',
        games: []
    };

    render() {
        const {className, games} = this.props;
        return (
            <div
                className={classNames('section cta-game-item', className)}>
                <ul className="game-list clearfix">
                    {games.map((game) => {
                        const {title, imageUrl} = game;
                        return (
                            <li className="game col-lg-3 col-xs-6" key={title}>
                                <div className="image-section">
                                    <img className="image" alt={title} src={imageUrl} />
                                </div>
                                <div className="game-title-section">
                                    <h1 className="game-title">{title}</h1>
                                </div>
                                <div className="download-section">
                                    <a href={game.appStoreLink} target="_blank" rel="noopener noreferrer">
                                        <div className="apple-download" />
                                    </a>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
