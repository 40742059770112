import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {isMobile} from '../../utils/BrowserUtils';

export default class Video extends React.Component {
    static propTypes = {
        video: PropTypes.string,
        align: PropTypes.string,
        active: PropTypes.bool,
        play: PropTypes.bool,
        playPauseFunc: PropTypes.func,
        poster: PropTypes.bool,
        backShadow: PropTypes.bool,
        buttonColor: PropTypes.string
    };

    render() {
        const {video, active} = this.props;
        const embedStyle = 'video-embed';
        const playOverlay = this.props.poster ? (
            <div role="presentation" className={`play-pause-overlay ${this.props.play ? '' : 'show'}`} onClick={this.props.playPauseFunc}>
                <div className="container-fluid play-pause-btn">
                    <span className={`${this.props.buttonColor}`}>
                        <span className="icon-play" />
                    </span>
                </div>
            </div>
        ) : (
            <div className={`container-fluid play-btn ${this.props.play ? '' : 'show'}`}>
                <a role="presentation" className={`${this.props.buttonColor}`} onClick={this.props.playPauseFunc}> {/*eslint-disable-line*/}
                    <span className="icon-play" />
                </a>
            </div>
        );

        return (
            <div id="video" className={this.props.play ? 'video-container carousel-video' : `video-container ${this.props.align}`}>
                <div className={classNames('content-vid')}>
                    <div className={this.props.backShadow ? `${embedStyle} backShadow` : embedStyle}>
                        <div className={`youtube-video trailer-video embed-responsive-item ${active ? 'show' : ''}`}>
                            {playOverlay}
                            {/* Using BackgroundVideo was hiding controls on mobile */}
                            <iframe
                                title="title"
                                width={isMobile() ? 'auto' : '700px'}
                                height={isMobile() ? 'auto' : '400px'}
                                src={`https://www.youtube-nocookie.com/embed/${video}`}
                                frameBorder="0"
                                allowFullScreen />

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
