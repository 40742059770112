import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CtaModalActions from '../../../actions/CtaModalActions';

export default class BuyNowSection extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        sectionData: PropTypes.object
    };
    static defaultProps = {
        className: '',
        sectionData: {}
    };

    toggleModal = () => {
        CtaModalActions.toggleModal.defer();
    }

    render() {
        const {
            className,
            sectionData
        } = this.props;
        const {backgroundImage} = sectionData;
        const logo = decodeURIComponent(sectionData.logo);
        const containerStyle = {
            backgroundImage: `url(${backgroundImage})`
        };
        return (
            <div className={classNames('buy-now-section section cta-section', className)} style={containerStyle}>
                <div className="section-wrapper">
                    <div className="logo-section">
                        <img
                            alt="ib-logo"
                            src={logo}
                            className="logo ib-logo" />
                    </div>
                </div>
            </div>
        );
    }
}
