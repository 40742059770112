import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {generateRoutePath} from 'epic-common/src/route';
import CommonCarousel from '../../common/CommonCarousel';
import {isMobile, isTablet} from '../../../utils/BrowserUtils';
import Message from '../../message/Message';

export default class GameInfoSection extends React.Component {
    state = {
        activeSlideIndex: 0
    }

    static propTypes = {
        className: PropTypes.string,
        sectionData: PropTypes.object
    };

    static defaultProps = {
        className: '',
        sectionData: {}
    };

    createUpdateActiveSlideFn = index => () => {
        this.setState({
            activeSlideIndex: index
        });
    }

    onChangeSelect = (event) => {
        const index = parseInt(event.target.value, 0); // the value would be the index of the item
        const updateActiveSlide = this.createUpdateActiveSlideFn(index);
        updateActiveSlide(index);
    }

    getUniqueClass = (activeInfo) => {
        if (!activeInfo) return '';
        const titlePartOne = activeInfo.titlePartOne.toLowerCase();
        const titlePartTwo = activeInfo.titlePartTwo.toLowerCase();
        return `${titlePartOne.replace(/\s/, '-')}-${titlePartTwo.replace(/\s/, '-')}`;
    }

    useSelectDropDown = () => isMobile() || isTablet()

    render() {
        const {
            className,
            sectionData
        } = this.props;
        const {activeSlideIndex} = this.state;
        const gameInfoPreviewList = sectionData.gameInfoPreviewList || [];
        const activeInfo = gameInfoPreviewList.filter((linkData, index) => index === activeSlideIndex)[0];
        let previewLinks = null;
        const uniqueClass = this.getUniqueClass(activeInfo);
        const informationSlideStyle = {
            backgroundImage: `url(${activeInfo.backgroundImage})`
        };

        if (activeInfo.moreLinks) {
            previewLinks = activeInfo.previewLinks.links;
        }

        return activeInfo ? (
            <div className={classNames('section game-info-section', className, uniqueClass)}>
                {this.useSelectDropDown() ? (
                    <select
                        className="game-select"
                        value={activeSlideIndex}
                        onChange={this.onChangeSelect}>
                        {gameInfoPreviewList.map((linkData, index) => {
                            const key = `${linkData.titlePartOne}-${index}`;
                            const {titlePartOne, titlePartTwo} = linkData;
                            const combinedTitle = `${titlePartOne} ${titlePartTwo}`;
                            return (
                                <option
                                    key={key}
                                    value={index}>
                                    {combinedTitle}
                                </option>
                            );
                        })}
                    </select>
                ) : (
                    <ul className="links">
                        {gameInfoPreviewList.map((linkData, index) => {
                            const isActive = index === activeSlideIndex;
                            const key = `${linkData.titlePartOne}-${index}`;
                            return (
                                <li className={classNames('link-container', {active: isActive})} key={key}>
                                    <button
                                        className="link-button"
                                        onClick={this.createUpdateActiveSlideFn(index)}>
                                        <p className="text text-part-one">
                                            {linkData.titlePartOne}
                                        </p>
                                        <p className="text text-part-two">
                                            {linkData.titlePartTwo}
                                        </p>
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                )}
                <div className="info-bg" style={informationSlideStyle}>
                    {activeInfo && activeInfo.moreLinks ? (
                        <div className="info-container more-links">
                            <div className="description-section">
                                <p className="description" dangerouslySetInnerHTML={{__html: activeInfo.description}} />
                            </div>
                            <div className="links">
                                <div className="carousel-container game-info-carousel">
                                    <CommonCarousel
                                        slidesToShow={2}
                                        infinite={true}
                                        dots={true}
                                        variableWidth={false}
                                        dotsClass="custom-pag custom-dots game-info-dots">
                                        {previewLinks && previewLinks.map((moreLink, index) => {
                                            const {title, link, logo} = moreLink;
                                            return (
                                                <div className="showcase-container" key={title}>
                                                    <div className="slide-image-container">
                                                        <a className="link" target="_blank" rel="noopener noreferrer" href={link}>
                                                            <img
                                                                alt="More links"
                                                                className="slide-image"
                                                                src={decodeURIComponent(logo)} />
                                                        </a>
                                                    </div>
                                                    <div className="slide-description">
                                                        <h3 className="showcase-title">{title}</h3>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </CommonCarousel>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="info-container">
                            <div className="img-section">
                                <img
                                    alt="Slider active logo"
                                    src={decodeURIComponent(activeInfo.logo)}
                                    className="active-img" />
                            </div>
                            <div className="content-wrapper">
                                <div className="description-section">
                                    <p className="description" dangerouslySetInnerHTML={{__html: activeInfo.description}} />
                                    <div className="learn-more-section">
                                        <Link className="link" to={generateRoutePath(activeInfo.learnMoreLink)}>
                                            <Message code="epic.infinityblade.learn" />
                                            <span className="icon-chevron-right" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="download-link-section">
                                    { activeInfo.downloadLink ?
                                        <a className="link" target="_blank" rel="noopener noreferrer" href={activeInfo.downloadLink}>
                                            <div className="download-img" />
                                        </a>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        ) : null;
    }
}
