import PropTypes from 'prop-types';
import EpicGamesNavImpl from 'epic-games-nav/app/scripts/EpicGamesNavImpl';
import CtaModalActions from '../../actions/CtaModalActions';
import {processParams} from 'epic-common/src/url';

class EpicGamesNavWrapper extends EpicGamesNavImpl {
    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
        localizationStore: PropTypes.object,
        headerStore: PropTypes.object
    };

    setCTA() {
        const config = this.props.headerStore.config ? this.props.headerStore.config.asMutable({deep: true}) : {};
        const {callToAction} = config;
        if (callToAction && callToAction.useCustomCallback) {
            callToAction.onClick = (e) => {
                e.preventDefault();
                CtaModalActions.toggleModal.defer();
            };
        }

        return callToAction;
    }

    setAccountLinks(accountLinks) {
        let newAccountLinks = accountLinks;
        const params = {
            state: this.props.location.pathname || '/'
        };
        const linkQueryString = `?${processParams(params)}`;
        if (newAccountLinks.signInLinks && newAccountLinks.signInLinks.length) {
            newAccountLinks = newAccountLinks.setIn(['signInLinks', 0, 'href'], `/infinityblade/login${linkQueryString}`);
        }
        if (newAccountLinks.signOutLink) {
            newAccountLinks = newAccountLinks.setIn(['signOutLink', 'href'], `/infinityblade/logout${linkQueryString}`);
        }
        return newAccountLinks;
    }
}

export default EpicGamesNavWrapper;
