import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import qs from 'qs';
import BuyNowGameItems from './BuyNowGameItems';
import FadeIn from '../../common/animations/FadeIn';
import CtaModalActions from '../../../actions/CtaModalActions';

/*eslint-disable jsx-a11y/click-events-have-key-events */
export default class BuyNowCTAModalSection extends React.Component {
    static propTypes = {
        history: PropTypes.object,
        location: PropTypes.object,
        className: PropTypes.string,
        sectionData: PropTypes.object,
        show: PropTypes.bool
    };

    static defaultProps = {
        history: {},
        location: {},
        className: '',
        sectionData: {},
        show: false
    };

    toggleModal = () => {
        CtaModalActions.toggleModal.defer();
    }

    closeModal = () => {
        const {history} = this.props;
        const location = this.props.location || {};
        const {search} = location;
        const params = qs.parse(search.replace(/\?/g, ''));
        const {showModal} = params;
        CtaModalActions.closeModal.defer();
        if (showModal) {
            history.push(location.path);
        }
    }

    render() {
        const {
            className,
            show,
            sectionData
        } = this.props;
        const {games} = sectionData;
        return (
            <FadeIn>
                {show ? (
                    <div
                        role="button"
                        tabIndex={0}
                        key="content"
                        className={classNames('section cta-modal', className)}
                        onClick={this.closeModal}>
                        <div className="box">
                            <div className="close-section row">
                                <button className="close-btn" onClick={this.closeModal}>
                                    <span className="icon-cross close-icon" />
                                </button>
                            </div>
                            <div className="content-wrapper row">
                                <BuyNowGameItems games={games} />
                            </div>
                        </div>
                    </div>
                ) : <div key="blank" />}
            </FadeIn>
        );
    }
}
