import React from 'react';
import PropTypes from 'prop-types';
import BuyNowSection from '../../components/sections/home/BuyNowSection';
import GameInfoSection from '../../components/sections/home/GameInfoSection';
import TestimonialSection from '../../components/sections/home/TestimonialSection';
import CommunitySection from '../../components/sections/home/CommunitySection';
import connectToStores from 'alt-utils/lib/connectToStores';
import HomeStore from '../../stores/HomeStore';

@connectToStores
export default class HomeView extends React.PureComponent {
    static propTypes = {
        homeStore: PropTypes.object
    };

    static defaultProps = {
        homeStore: {}
    };

    static getStores() {
        return [HomeStore];
    }

    static getPropsFromStores() {
        return {
            homeStore: HomeStore.getState()
        };
    }

    render() {
        const {homeStore} = this.props;
        const ctaSection = homeStore.ctaSection || {};
        const {gameInfoPreviewSection, testimonialSection, communitySection} = homeStore;
        return (
            <div id="ib-home" className="home-view">
                <div className="home-view-container">
                    <BuyNowSection
                        sectionData={ctaSection} />
                    <GameInfoSection
                        sectionData={gameInfoPreviewSection} />
                    <TestimonialSection
                        sectionData={testimonialSection} />
                    <CommunitySection
                        sectionData={communitySection} />
                </div>
            </div>
        );
    }
}
