import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default class SocialShareIcon extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.string,
        link: PropTypes.string
    };

    static defaultProps = {
        className: '',
        type: ''
    };

    render() {
        const {type, className, link} = this.props;
        return (
            <a href={link} target="_blank" rel="noopener noreferrer" className={classNames('', className)}>
                <li className="social-share">
                    <span className={`icon-${type}`} />
                </li>
            </a>
        );
    }
}
